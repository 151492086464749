import React from "react"
import Helmet from "react-helmet"
import { graphql } from "gatsby"

import Seo from "@components/Seo"
import {
  getCaseStudyEdges,
  useAllCaseStudies,
} from "@components/utils/useAllCaseStudies"
import { CaseStudyLegacyHero } from "@components/sections/heros/CaseStudyLegacyHero"
import { WebCaseStudyContent } from "@components/sections/web-case-study/WebCaseStudyContent"
import { CaseStudyArrows } from "@components/sections/case-study/CaseStudyArrows"

export default function WebCaseStudyLegacyPage({ data }) {
  const page = data.allWpCaseStudy.nodes[0]

  const { next, previous } = getCaseStudyEdges(
    useAllCaseStudies(),
    page.databaseId
  )
  const { title, featuredImage } = page
  const {
    subTitle,
    featuredColour,
    hero,
    url,
    bannerText,
    caseStudyContent,
  } = page?.acfWebCaseStudy

  return <>
    <Seo {...page.seo} />
    <Helmet bodyAttributes={{ class: "full-hero" }} />

    <CaseStudyLegacyHero
      nextCaseStudy={next}
      previousCaseStudy={previous}
      taxServices={page.taxServices?.nodes}
      heroTitle={subTitle || bannerText}
      projectName={title}
      featuredColour={featuredColour}
      gatsbyImageData={
        (hero ? hero[0].image?.localFile?.childImageSharp?.gatsbyImageData : null) ||
        featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData
      }
    />

    <WebCaseStudyContent
      contentSections={caseStudyContent}
      featuredColour={featuredColour}
      siteURL={url}
    />

    <CaseStudyArrows nextCaseStudy={next} previousCaseStudy={previous} />
  </>;
}

export const query = graphql`fragment webCaseStudyData on WpCaseStudy {
  taxServices {
    nodes {
      slug
      name
    }
  }
  featuredImage {
    node {
      localFile {
        extension
        publicURL
        childImageSharp {
          gatsbyImageData(
            quality: 85
            transformOptions: {cropFocus: CENTER}
            layout: FULL_WIDTH
          )
        }
      }
    }
  }
  acfWebCaseStudy {
    url
    subTitle
    featuredColour
    bannerText
    hero {
      image {
        localFile {
          extension
          publicURL
          childImageSharp {
            gatsbyImageData(
              quality: 85
              transformOptions: {cropFocus: CENTER}
              layout: FULL_WIDTH
            )
          }
        }
      }
    }
    caseStudyContent {
      ... on WpCaseStudy_Acfwebcasestudy_CaseStudyContent_BackgroundSection {
        fieldGroupName
        background
        resultsHighlight {
          percentage
          text
        }
      }
      ... on WpCaseStudy_Acfwebcasestudy_CaseStudyContent_ObjectiveApproachSection {
        fieldGroupName
        approach: approch
        objective
        backgroundColour
        middleImage {
          localFile {
            extension
            publicURL
            childImageSharp {
              gatsbyImageData(
                quality: 85
                transformOptions: {cropFocus: CENTER}
                layout: FULL_WIDTH
              )
            }
          }
        }
        leftImage {
          localFile {
            extension
            publicURL
            childImageSharp {
              gatsbyImageData(
                quality: 85
                transformOptions: {cropFocus: CENTER}
                layout: FULL_WIDTH
              )
            }
          }
        }
        rightImage {
          localFile {
            extension
            publicURL
            childImageSharp {
              gatsbyImageData(
                quality: 85
                transformOptions: {cropFocus: CENTER}
                layout: FULL_WIDTH
              )
            }
          }
        }
      }
      ... on WpCaseStudy_Acfwebcasestudy_CaseStudyContent_SeeItInAction {
        fieldGroupName
        tabletImage {
          localFile {
            extension
            publicURL
            childImageSharp {
              gatsbyImageData(
                quality: 85
                transformOptions: {cropFocus: CENTER}
                layout: FULL_WIDTH
              )
            }
          }
        }
        leftImage {
          localFile {
            extension
            publicURL
            childImageSharp {
              gatsbyImageData(
                quality: 85
                transformOptions: {cropFocus: CENTER}
                layout: FULL_WIDTH
              )
            }
          }
        }
      }
      ... on WpCaseStudy_Acfwebcasestudy_CaseStudyContent_DeliveryFeedbackSection {
        fieldGroupName
        deliveryText
        feedBack
        images {
          image {
            localFile {
              extension
              publicURL
              childImageSharp {
                gatsbyImageData(
                  quality: 85
                  transformOptions: {cropFocus: CENTER}
                  layout: FULL_WIDTH
                )
              }
            }
          }
        }
      }
      ... on WpCaseStudy_Acfwebcasestudy_CaseStudyContent_BeforeAfter {
        fieldGroupName
        beforeImage {
          localFile {
            extension
            publicURL
            childImageSharp {
              gatsbyImageData(
                quality: 85
                placeholder: BLURRED
                transformOptions: {cropFocus: CENTER}
                layout: FULL_WIDTH
              )
            }
          }
        }
        afterImage {
          localFile {
            extension
            publicURL
            childImageSharp {
              gatsbyImageData(
                quality: 85
                placeholder: BLURRED
                transformOptions: {cropFocus: CENTER}
                layout: FULL_WIDTH
              )
            }
          }
        }
      }
    }
  }
}

query ($slug: String!) {
  allWpCaseStudy(filter: {slug: {eq: $slug}}) {
    nodes {
      databaseId
      id
      uri
      title
      link
      seo {
        canonical
        cornerstone
        focuskw
        metaDesc
        metaKeywords
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        opengraphModifiedTime
        opengraphPublishedTime
        opengraphPublisher
        opengraphImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, layout: FIXED)
            }
          }
        }
        opengraphSiteName
        opengraphTitle
        opengraphType
        opengraphUrl
        title
        twitterDescription
        twitterTitle
        schema {
          raw
        }
      }
      ...webCaseStudyData
    }
  }
}
`
