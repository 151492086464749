import React from "react"
import styled from "styled-components"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/swiper.scss"

import BackgroundImage from "gatsby-background-image"

import Button from "@components/ui/buttons/Button"
import SocialLinks from "@components/ui/social/SocialLinks"
import classnames from "classnames"
import { breakpoints, breakpointsMedia } from "@styles/variables/Breakpoint"
import Heading from "@components/ui/Heading"

const DeliveryFeedbackStyled = styled.div`
  background: #323539;
  color: var(--color-text-light);
  padding: 0;

  .container-fluid {
    max-width: 1660px;
  }

  .content-section {
    padding-top: 60px;
    text-align: left;
    .top-section {
      &:after {
        display: none;
      }
    }
    p {
      color: var(--color-text-light);
      strong {
        font-weight: 700;
      }
    }
  }

  .swiper-pagination {
    position: absolute;
    left: 50%;
    bottom: 0;
    margin-top: 0;
    transform: translateX(-50%);
    text-align: center;
    padding: 0;
    z-index: 10;

    .swiper-pagination-bullet {
      margin: 5px;
      opacity: 1;
      height: 24px;
      width: 24px;
      border: 5px solid var(--color-primary);
      background-color: var(--color-primary-darkened);
      border-radius: 100%;
      display: inline-block;
      cursor: pointer;
      transition: all 0.15s;
      color: transparent;
      outline: none;

      &:hover {
        background-color: var(--color-bg);
      }

      &.swiper-pagination-bullet-active {
        background-color: var(--color-bg);
      }
    }
  }

  .image-section {
    padding-top: 0;

    svg {
      z-index: 10;
      path {
        fill: #323539;
      }
    }

    .image {
      height: 300px;

      ${breakpointsMedia(breakpoints.phoneWide)} {
        height: 400px;
      }
      ${breakpointsMedia(breakpoints.tablet)} {
        height: 500px;
      }
      ${breakpointsMedia(breakpoints.tabletWide)} {
        height: 600px;
      }
      ${breakpointsMedia(breakpoints.desktop)} {
        height: 700px;
      }
    }
  }

  .content-section {
    .delivery_buttons {
      margin-top: 60px;
      display: flex;
      flex-wrap: wrap;

      .the_button {
        margin-bottom: 30px;

        &:hover {
          .btn {
            color: #333;
          }
        }
      }
    }

    .share-wrap {
      margin-bottom: 30px;
      ${breakpointsMedia(breakpoints.tablet)} {
      }
      color: var(--color-text-light);

      > span {
        margin-right: 15px;
      }
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-left: 30px;
    }

    .bottom-section {
      position: relative;

      .btn {
        display: block;
        margin: 30px auto 0;

        ${breakpointsMedia(breakpoints.tablet)} {
          position: absolute;
          bottom: 0;
          right: 0;
          margin: auto;
        }
      }
    }

    .highlights {
      padding: 60px 0;
      margin-bottom: 60px;
      position: relative;

      &:before {
        height: 5px;
        width: 70px;
        background: #5a6970;
        position: absolute;
        bottom: 0;
        left: 15px;
      }

      .col-sm-4 {
        width: auto;
        padding-right: 60px;
        position: relative;
        margin: 0 0 30px;

        .highlight-title {
          margin-bottom: 30px;
          text-transform: uppercase;
          color: #5a6970;
          font-size: 80px;
          line-height: 62px;
        }

        &:before {
          ${breakpointsMedia(breakpoints.tablet)} {
            content: "";
            height: 100%;
            width: 1px;
            background: #5a6970;
            position: absolute;
            top: 0;
            right: 15px;
          }
        }

        &:last-child {
          &:before {
            width: 0;
          }
        }
      }
    }
  }
`

export const DeliveryFeedback = ({ content, feedBack, siteURL, images }) => {
  const uri = typeof window !== "undefined" ? window.location.href : null

  return (
    <DeliveryFeedbackStyled
      id="delivery_feedback_section"
      className="section-container content-area no-top no-bottom"
    >
      {images && (
        <div className="image-section">
          <Swiper
            style={{
              position: `relative`,
            }}
            slidesPerView="auto"
            loop="true"
            pagination={{ clickable: true }}
            centeredSlides="true"
          >
            {images.map(({ image }, key) => {
              return (
                <SwiperSlide key={key}>
                  <BackgroundImage
                    className="image"
                    {...image?.localFile?.childImageSharp}
                  />
                </SwiperSlide>
              )
            })}
          </Swiper>
        </div>
      )}

      <div className="content-section">
        <div className="container-fluid">
          <div className="row">
            <div className="top-section col-md-12">
              <Heading node="h2" color="white">
                Delivery
              </Heading>

              <span dangerouslySetInnerHTML={{ __html: content }} />

              <div className="delivery_buttons clearfix">
                {siteURL && (
                  <div className="the_button ">
                    <Button
                      color="white"
                      href={siteURL}
                      target="_blank"
                      rel="noopener"
                    >
                      view live site
                    </Button>
                  </div>
                )}
                <div className="share-wrap">
                  <span>Share</span>
                  <SocialLinks uri={uri} />
                </div>
              </div>
            </div>

            {feedBack && (
              <div
                className={classnames(
                  `bottom-section col-md-${content ? "6" : "12"}`
                )}
              >
                <Heading node="h2">Feedback</Heading>
                <span dangerouslySetInnerHTML={{ __html: feedBack }} />
              </div>
            )}
          </div>
        </div>
      </div>
    </DeliveryFeedbackStyled>
  )
}

DeliveryFeedback.propTypes = {}

export default DeliveryFeedback
