import { graphql, useStaticQuery } from "gatsby"

export const useAllCaseStudies = () => {
  const {
    allWpCaseStudy: { caseStudies },
  } = useStaticQuery(graphql`
    query {
      allWpCaseStudy(
        sort: { order: ASC, fields: contentType___node___menuPosition }
      ) {
        caseStudies: nodes {
          databaseId
          id
          title
          uri
        }
      }
    }
  `)

  return caseStudies
}

export function getCaseStudyEdges(caseStudies, databaseId) {
  if (caseStudies) {
    for (let i = 0; i < caseStudies.length; i++) {
      if (caseStudies[i].databaseId === databaseId) {
        return {
          previous: caseStudies[i - 1],
          next: caseStudies[i + 1],
        }
      }
    }
  }

  return null
}
