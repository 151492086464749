import React from "react"
import * as PropTypes from "prop-types"
import Loadable from "@loadable/component"

import { BackgroundSection } from "./BackgroundSection"
import { ObjectiveApproach } from "./ObjectiveApproach"
import { SeeItInAction } from "./SeeItInAction"
import { DeliveryFeedback } from "../DeliveryFeedback"

const BeforeAfterSlider = Loadable(() => import("../BeforeAfterSlider"))

export const WebCaseStudyContent = ({ contentSections, siteURL }) => {
  return contentSections
    .filter(contentSection => contentSection)
    .map((acfLayout, key) => {
      let section = <></>

      switch (acfLayout.fieldGroupName) {
        case "caseStudy_Acfwebcasestudy_CaseStudyContent_BackgroundSection":
          section = (
            <BackgroundSection
              key={key}
              content={acfLayout.background}
              resultsHighlight={acfLayout.resultsHighlight}
            />
          )
          break
        case "caseStudy_Acfwebcasestudy_CaseStudyContent_ObjectiveApproachSection":
          section = (
            <ObjectiveApproach
              key={key}
              backgroundColour={acfLayout.backgroundColour}
              approach={acfLayout.approach}
              objective={acfLayout.objective}
              leftImage={acfLayout.leftImage}
              middleImage={acfLayout.middleImage}
              rightImage={acfLayout.rightImage}
            />
          )
          break
        case "caseStudy_Acfwebcasestudy_CaseStudyContent_SeeItInAction":
          section = (
            <SeeItInAction
              key={key}
              leftImage={acfLayout.leftImage}
              rightImage={acfLayout.tabletImage}
              siteURL={siteURL}
            />
          )
          break
        case "caseStudy_Acfwebcasestudy_CaseStudyContent_DeliveryFeedbackSection":
          section = (
            <DeliveryFeedback
              key={key}
              siteURL={siteURL}
              content={acfLayout.deliveryText}
              feedBack={acfLayout.feedBack}
              images={acfLayout.images}
            />
          )
          break
        case "caseStudy_Acfwebcasestudy_CaseStudyContent_BeforeAfter":

          section = (
            <BeforeAfterSlider
              key={key}
              beforeImageURL={
                acfLayout.beforeImage.localFile.publicURL
              }
              afterImageURL={
                acfLayout.afterImage.localFile.publicURL
              }
            />
          )
          break
        default:
          break
      }

      return section
    });
}

WebCaseStudyContent.propTypes = {
  contentSections: PropTypes.array.isRequired,
}
