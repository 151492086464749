import React from "react"
import styled from "styled-components"
import CustomGatsbyImage from "@components/utils/CustomGatsbyImage"
import { breakpoints, breakpointsMedia } from "@styles/variables/Breakpoint"
import Heading from "@components/ui/Heading"

const ObjectiveApproachStyled = styled.section`
  background: #fff;
  padding: 0;
  text-align: left;

  .content-section {
    background: #fff;
    padding-top: 60px;

    .objective-approach {
      max-width: 1660px;
    }
  }
  .top-section {
    &:after {
      display: none;
    }
    ${breakpointsMedia(breakpoints.tablet)} {
      width: 272px;
    }
    .content-title {
      font-size: 40px;
    }
    .btn-default {
      width: 100%;
      color: #5a6970;
      border-color: #5a6970;
      font-size: 16.5px;
      margin-bottom: 30px;
    }
  }
  .bottom-section {
    position: relative;

    .objective {
      position: relative;
      ${breakpointsMedia(breakpoints.tabletWide)} {
        padding-right: 45px;
      }
    }
    .approach {
      ${breakpointsMedia(breakpoints.tabletWide)} {
        padding-left: 45px;
      }
    }
  }

  .image-section {
    background-position: center;
    background-size: cover;
    position: relative;
    overflow: hidden;
    background: #8399a4;
    margin-bottom: 0;
    padding-top: 30px;
    height: 150px;
    ${breakpointsMedia(breakpoints.phone)} {
      height: 250px;
    }
    ${breakpointsMedia(breakpoints.tablet)} {
      height: 400px;
    }
    ${breakpointsMedia(breakpoints.tabletWide)} {
      height: 450px;
    }
    ${breakpointsMedia(breakpoints.desktop)} {
      height: 500px;
    }
    @media (min-width: 1510px) {
      height: 550px;
    }
    .content-section {
      background: #fff;
    }
    .image_container {
      position: relative;

      margin: auto;
      width: 320px;
      max-width: 100%;
      ${breakpointsMedia(breakpoints.phone)} {
        width: 470px;
      }
      ${breakpointsMedia(breakpoints.tablet)} {
        width: 768px;
      }
      ${breakpointsMedia(breakpoints.tabletWide)} {
        width: 992px;
      }
      ${breakpointsMedia(breakpoints.desktop)} {
        width: 1200px;
      }
      @media (min-width: 1510px) {
        width: 1500px;
      }
      @media (min-width: 1670px) {
        width: 1660px;
      }
      .image_display {
        position: absolute;
        padding: 8px 0px 0;
        background: #d9dfe2;

        border-top-left-radius: 5px;
        border-top-right-radius: 5px;

        ${breakpointsMedia(breakpoints.phone)} {
          padding: 11px 0px 0;
        }
        ${breakpointsMedia(breakpoints.tablet)} {
          padding: 14px 0px 0;
        }
        ${breakpointsMedia(breakpoints.tabletWide)} {
          padding: 17px 0px 0;
        }
        ${breakpointsMedia(breakpoints.desktop)} {
          padding: 20px 0px 0;
        }
        .image_wrap {
          padding: 0px 3px 0;
          background: #d9dfe2;
        }
        .dots {
          position: absolute;

          left: 8px;
          top: 1.5px;
          ${breakpointsMedia(breakpoints.phone)} {
            top: 3px;
          }
          ${breakpointsMedia(breakpoints.tablet)} {
            top: 4.5px;
          }
          ${breakpointsMedia(breakpoints.tabletWide)} {
            top: 6px;
          }
          ${breakpointsMedia(breakpoints.desktop)} {
            top: 7.5px;
          }
          span {
            height: 5px;
            width: 5px;
            border-radius: 50%;
            background: #909496;
            display: block;
            float: left;
            margin-left: 5px;
          }
        }
        &.left {
          left: 0;
          z-index: 1;
          width: 126px;
          height: 100%;
          top: 14px;
          ${breakpointsMedia(breakpoints.phone)} {
            width: 184px;
            top: 21px;
          }
          ${breakpointsMedia(breakpoints.tablet)} {
            width: 261px;
            top: 34px;
          }
          ${breakpointsMedia(breakpoints.tabletWide)} {
            width: 329px;
            top: 44px;
          }
          ${breakpointsMedia(breakpoints.desktop)} {
            width: 420px;
            top: 53px;
          }
          @media (min-width: 1510px) {
            width: 495px;
            top: 66px;
          }
          @media (min-width: 1670px) {
            top: 66px;
            width: 500px;
          }
        }
        &.right {
          width: 126px;
          height: 100%;
          top: 33px;

          right: 0;
          z-index: 2;
          ${breakpointsMedia(breakpoints.phone)} {
            width: 184px;
            top: 49px;
          }
          ${breakpointsMedia(breakpoints.tablet)} {
            width: 261px;
            top: 79px;
          }
          ${breakpointsMedia(breakpoints.tabletWide)} {
            width: 329px;
            top: 103px;
          }
          ${breakpointsMedia(breakpoints.desktop)} {
            width: 420px;
            top: 124px;
          }
          @media (min-width: 1510px) {
            width: 495px;
            top: 155px;
          }
          @media (min-width: 1670px) {
            top: 172px;
            width: 500px;
          }
        }
        &.middle {
          z-index: 3;
          width: 144px;
          top: 0;
          height: 100%;
          left: 50%;
          margin-left: -72px;

          ${breakpointsMedia(breakpoints.phone)} {
            width: 212px;
            margin-left: -107px;
          }
          ${breakpointsMedia(breakpoints.tablet)} {
            width: 346px;
            margin-left: -173px;
          }
          ${breakpointsMedia(breakpoints.tabletWide)} {
            width: 446px;
            margin-left: -223px;
          }
          ${breakpointsMedia(breakpoints.desktop)} {
            width: 540px;
            margin-left: -270px;
          }
          @media (min-width: 1510px) {
            width: 676px;
            margin-left: -338px;
          }
          @media (min-width: 1670px) {
            width: 747px;
            margin-left: -373.5px;
          }
        }
      }
    }
    ${breakpointsMedia(breakpoints.tablet)} {
      padding-top: 60px;
    }
    svg {
      position: absolute;
      bottom: -1px;
      left: 0;
      z-index: 4;
      ${breakpointsMedia(breakpoints.tabletWide)} {
        bottom: -45px;
      }
      path {
        fill: #e5e5e5;
      }
    }
  }
`

export const ObjectiveApproach = ({
  backgroundColour,
  objective,
  approach,
  leftImage,
  middleImage,
  rightImage,
}) => {
  return (
    <ObjectiveApproachStyled
      id="objective_approach_section"
      className="section-container content-area no-top"
    >
      <div
        className="image-section hidden-xs"
        style={{ backgroundColor: backgroundColour }}
      >
        <div className="image_container">
          {leftImage && (
            <div
              className="image_display left "
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <div className="dots">
                <span />
                <span />
                <span />
              </div>
              <div className="image_wrap">
                <CustomGatsbyImage {...leftImage.localFile} />
              </div>
            </div>
          )}
          {middleImage && (
            <div
              className="image_display middle "
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay={1000}
            >
              <div className="dots">
                <span />
                <span />
                <span />
              </div>
              <div className="image_wrap">
                <CustomGatsbyImage {...middleImage.localFile} />
              </div>
            </div>
          )}
          {rightImage && (
            <div
              className="image_display right "
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay={2000}
            >
              <div className="dots">
                <span />
                <span />
                <span />
              </div>
              <div className="image_wrap">
                <CustomGatsbyImage {...rightImage.localFile} />
              </div>
            </div>
          )}
        </div>
      </div>
      {(objective || approach) && (
        <div className="content-section">
          <div className="container-fluid objective-approach">
            <div className="bottom-section">
              <div className="row">
                {objective && (
                  <div className="col-md-6 objective">
                    <Heading node="h2">Objective</Heading>
                    <span dangerouslySetInnerHTML={{ __html: objective }} />
                  </div>
                )}
                {approach && (
                  <div className="col-md-6 approach">
                    <Heading node="h2">Approach</Heading>
                    <span dangerouslySetInnerHTML={{ __html: approach }} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </ObjectiveApproachStyled>
  )
}

ObjectiveApproach.propTypes = {}
