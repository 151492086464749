import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import { GatsbyImage } from "gatsby-plugin-image"
import { HeroStyle } from "@styles/organisms/Hero"
import styled from "styled-components"
import { breakpoints, breakpointsMedia } from "@styles/variables/Breakpoint"

import classnames from "classnames"
import useMobile from "@components/utils/useMobile"
import { CaseStudyHeroNav } from "./CaseStudyHeroNav"
import { convertToBgImage } from "gbimage-bridge"
import Heading from "@components/ui/Heading"

const Hero = styled(HeroStyle)`
  width: 100%;
  background-color: var(--color-primary);
  overflow: hidden;
  position: relative;
  height: 50vh;
  min-height: 500px;

  ${breakpointsMedia(breakpoints.tablet)} {
    height: 750px;
  }

  .background-image {
    width: 100%;
    height: 100%;
    position: absolute;

    .gatsby-image-wrapper {
      width: 100%;
      height: 100%;
      position: absolute;
    }
  }

  .container {
    position: relative;
    height: 100%;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;

    .overlay {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
    }

    .upper-title {
      color: var(--color-secondary);
    }

    .inner {
      z-index: 3;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 100%;
      max-width: 950px;

      .content {
        text-align: center;
        padding: 0 15px;

        h1 {
          ${breakpointsMedia(breakpoints.tablet, "max")} {
            font-size: 32px;
            line-height: 46px;
          }
        }

        .btn-container {
          .btn {
            margin: 0 8px;
          }
        }
      }
    }

    .hero-bottom {
      position: absolute;
      bottom: 50px;

      .scroll-to-content {
        margin: 0;
        width: 100%;
        display: block;
      }
    }
  }

  .caption {
    font-size: 20px;
    font-weight: 700;
    top: 35%;
    left: 50%;
    transform: translateX(-50%);

    .subtitle {
      font-size: 70px;
      font-weight: 900;
      line-height: 84px;
    }
  }

  .project-info {
    position: absolute;
    bottom: 0;
    display: flex;
    z-index: 2;
    width: 100%;
    padding: 0 15px;
    flex-wrap: wrap;
    justify-content: space-between;

    .left-panel {
      ${breakpointsMedia(breakpoints.tabletWide, "max")} {
        display: none;
      }

      .project-info-inner {
        display: flex;
        height: 100%;
        padding: 0;

        .theme-colour {
          .circle {
            height: 28.1px;
            width: 28.1px;
            border-radius: 50%;
            background-color: var(--color-primary);
          }
        }

        .project-name {
          font-weight: 700;
          color: var(--color-text-light);
          padding-left: 15px;
          font-size: 19px;
        }

        .theme-colour,
        .project-name {
          align-self: center;
        }
      }
    }

    .right-panel {
      ${breakpointsMedia(breakpoints.tabletWide)} {
        position: absolute;
        right: 25px;
        bottom: 0;
      }

      text-align: right;

      .services-involved {
        .category-icons {
          float: none;
          display: flex;
          flex-wrap: wrap;

          .service-icon {
            width: auto;
            background: none;
            padding: 7px 6px;
            white-space: nowrap;
            display: flex;
            align-items: center;
            height: auto;

            &:hover {
              background-color: transparent !important;
              cursor: hand;
            }

            a {
              color: var(--color-text-light);
              font-weight: bold;
              font-size: 15px;
            }

            i {
              position: static;
              display: inline-block;
              float: left;
              margin-right: 15px;
              width: 28px;
              height: 28px;
              background-size: contain;
              background-position: center;
              background-repeat: no-repeat;
            }
          }
        }
      }

      .project-link {
        display: none;

        &:hover {
          text-decoration: none;
        }

        .view-link {
          background-color: #ffc25e;
          height: 100%;
          padding: 15px 30px 15px 60px;

          &:hover {
            background-color: #d29d46;
          }

          .link-icon {
            font-weight: 700;
            color: #3e1934;
            position: absolute;
            left: 22px;
            bottom: 12px;
            font-size: 30px;
          }

          .view-project {
            font-weight: 700;
            color: #3e1934;
          }
        }
      }
    }
  }
`

function getTaxToServiceData(slug) {
  const mapping = {
    seo: {
      shortTitle: "SEO",
      title: "Search Engine Optimizations",
      uri: "/services/seo/",
      iconID: "seo",
    },
    "insight-cro": {
      title: "Conversion",
      uri: "/services/conversion/",
      iconID: "conversion",
    },
    ppc: {
      shortTitle: "PPC",
      title: "Pay Per Click",
      uri: "/services/ppc/",
      iconID: "ppc",
    },
    "social-media-service": {
      title: "Social Media",
      uri: "/services/social-media/",
      iconID: "social",
    },
    "web-design": {
      title: "Web Design",
      uri: "/services/design-development/",
      iconID: "web",
    },
    "content-marketing": {
      title: "Content Marketing",
      uri: "/services/content-marketing/",
      iconID: "Content",
    },
  }

  return mapping[slug]
}

export const CaseStudyLegacyHero = ({
  heroTitle,
  gatsbyImageData,
  projectName,
  featuredColour,
  taxServices,
  nextCaseStudy,
  previousCaseStudy,
}) => {
  const { isMobile } = useMobile()

  return (
    <Hero>
      {gatsbyImageData && (
        <div className="background-image overlay">
          <GatsbyImage
            image={gatsbyImageData}
            imgStyle={{ objectPosition: "top center" }}
          />
        </div>
      )}
      <div className="container">
        <div className="inner">
          <div className="content">
            {projectName && <span className="upper-title">{projectName}</span>}

            <Heading color="white">
              <span dangerouslySetInnerHTML={{ __html: heroTitle }}></span>
            </Heading>
          </div>
        </div>

        <div className="project-info ">
          <div className="left-panel">
            <div className="project-info-inner">
              <div className="theme-colour">
                <div
                  className="circle"
                  style={{ backgroundColor: featuredColour }}
                />
              </div>
              <Heading node="h3" color="white" className="project-name">
                {projectName}
              </Heading>
            </div>
          </div>

          <div className="right-panel">
            <div className="services-involved">
              <div className="category-icons">
                {taxServices &&
                  taxServices.slice(0, 2).map(({ name, slug }) => {
                    const service = getTaxToServiceData(slug)

                    const shortTitle = service?.shortTitle
                    let title = service?.title || name

                    if (isMobile && shortTitle) {
                      title = shortTitle
                    }

                    return (
                      <span
                        key={slug}
                        title={name}
                        className={classnames("service-icon pink")}
                      >
                        <i className={`${service?.iconID || slug}-icon`} />
                        <Link
                          to={service?.uri || `/services/${slug}/`}
                          dangerouslySetInnerHTML={{
                            __html: title,
                          }}
                        />
                      </span>
                    )
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
      {(previousCaseStudy || nextCaseStudy) && (
        <CaseStudyHeroNav
          nextCaseStudy={nextCaseStudy}
          previousCaseStudy={previousCaseStudy}
        />
      )}
    </Hero>
  )
}

CaseStudyLegacyHero.propTypes = {
  heroTitle: PropTypes.string,
  upperTitle: PropTypes.string,
  featuredColour: PropTypes.string,
  nextCaseStudy: PropTypes.shape({
    uri: PropTypes.string,
  }),
  previousCaseStudy: PropTypes.shape({
    uri: PropTypes.string,
  }),
}
