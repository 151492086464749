import React from "react"
import PropTypes from "prop-types"
import Background from "gatsby-background-image"
import { convertToBgImage } from "gbimage-bridge"

const CustomBGImage = ({
  extension,
  childImageSharp,
  publicURL,
  children,
  ...rest
}) => {
  if (extension === "gif" && publicURL) {
    return (
      <div {...rest} style={{ backgroundImage: `url(${publicURL})` }}>
        {children}
      </div>
    )
  } else if (childImageSharp?.gatsbyImageData) {
    const image = convertToBgImage(childImageSharp?.gatsbyImageData)
    return (
      <Background
        {...rest}
        {...image}
      >
        {children}
      </Background>
    )
  }

  return <></>
}

CustomBGImage.propTypes = {
  extension: PropTypes.string,
  childImageSharp: PropTypes.object,
  publicURL: PropTypes.string,
}

export default CustomBGImage
