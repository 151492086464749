import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Link } from "gatsby"

const CaseStudyHeroNavStyled = styled.div`
  @media only screen and (min-width: 768px) {
    .hero-prev,
    .hero-next {
      height: 66px;
      width: 40px;
      margin: -20px 0 0;

      font-size: 66px;
      color: var(--color-text-light);

      position: absolute;
      top: 50%;
      z-index: 50;
    }

    .hero-prev {
      left: 2%;
    }

    .hero-next {
      right: 2%;
    }
  }
`

export const CaseStudyHeroNav = ({ previousCaseStudy, nextCaseStudy }) => {
  return (
    <CaseStudyHeroNavStyled>
      {(previousCaseStudy || nextCaseStudy) && (
        <div className="hero-nav">
          {previousCaseStudy && (
            <Link
              to={previousCaseStudy.uri}
              title={previousCaseStudy.title}
              className="hero-prev"
            >
              <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" data-prefix="fas"
                   data-icon="angle-left" className="svg-inline--fa fa-angle-left fa-w-8" role="img"
                   viewBox="0 0 256 512">
                <path fill="currentColor"
                      d="M31.7 239l136-136c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9L127.9 256l96.4 96.4c9.4 9.4 9.4 24.6 0 33.9L201.7 409c-9.4 9.4-24.6 9.4-33.9 0l-136-136c-9.5-9.4-9.5-24.6-.1-34z" />
              </svg>
            </Link>
          )}
          {nextCaseStudy && (
            <Link
              to={nextCaseStudy.uri}
              title={nextCaseStudy.title}
              className="hero-next"
            >
              <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" data-prefix="fas"
                   data-icon="angle-right" className="svg-inline--fa fa-angle-right fa-w-8" role="img"
                   viewBox="0 0 256 512">
                <path fill="currentColor"
                      d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z" />
              </svg>
            </Link>
          )}
        </div>
      )}
    </CaseStudyHeroNavStyled>
  )
}

CaseStudyHeroNav.propTypes = {
  nextCaseStudy: PropTypes.shape({
    uri: PropTypes.string,
  }),
  previousCaseStudy: PropTypes.shape({
    uri: PropTypes.string,
  }),
}

CaseStudyHeroNav.defaultProps = {}
