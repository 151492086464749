import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { breakpoints, breakpointsMedia } from "@styles/variables/Breakpoint"
import Heading from "@components/ui/Heading"

const BackgroundSectionStyled = styled.section`
  background: #d9dfe2;
  text-align: center;
  padding: 60px 0;

  ${breakpointsMedia(breakpoints.tabletWide)} {
    padding: 60px;
  }

  .content-body p,
  .content-area p {
    color: #788086;
  }

  .background-section-inner {
    width: 100%;
  }

  .results-highlights {
    margin-top: 60px;
    padding-top: 60px;
    position: relative;
    font-size: 20px;

    &:before {
      content: "";
      background: #5a6970;
      height: 4px;
      width: 64px;
      position: absolute;
      top: 0;
      left: 50%;
      margin-left: -32px;
    }
    .results-highlight {
      padding-bottom: 30px;

      &:nth-child(2) {
        border: 1px solid #cbd1d4;
        border-width: 1px 0;

        ${breakpointsMedia(breakpoints.tabletWide)} {
          border-width: 0 1px;
        }
      }

      .percentage {
        font-size: 45px;
        font-family: "Open Sans", sans-serif;
        span {
          font-size: 80px;
        }
      }
    }
  }
`

export const BackgroundSection = ({ title, content, resultsHighlight }) => {
  return (
    <BackgroundSectionStyled
      id="background_section"
      className="section-container content-area"
    >
      <div className="container background-section-inner">
        <div className="row">
          <div className="col-md-8 col-md-offset-2">
            <Heading node="h2">{title}</Heading>
            <div
              className="content-body"
              dangerouslySetInnerHTML={{ __html: content }}
            />
          </div>
        </div>

        {resultsHighlight && (
          <div className="row results-highlights">
            {resultsHighlight.map(({ percentage, text }) => {
              return (
                <div className="col-md-4 results-highlight">
                  <div className="percentage">
                    <span dangerouslySetInnerHTML={{ __html: percentage }} />
                  </div>
                  <div
                    className="result-text"
                    dangerouslySetInnerHTML={{ __html: text }}
                  />
                </div>
              )
            })}
          </div>
        )}
      </div>
    </BackgroundSectionStyled>
  )
}

BackgroundSection.propTypes = {
  title: PropTypes.string,
  resultsHighlight: PropTypes.arrayOf(
    PropTypes.shape({
      percentage: PropTypes.string,
      text: PropTypes.string,
    })
  ),
}

BackgroundSection.defaultProps = {
  title: `Background`,
}
