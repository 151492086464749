import React, { useState } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { breakpoints, breakpointsMedia } from "@styles/variables/Breakpoint"
import classnames from "classnames"
import useMobile from "@components/utils/useMobile"
import CustomBGImage from "@components/utils/CustomBGImage"
import Heading from "@components/ui/Heading"

const SeeItInActionStyled = styled.section`
    background: #e5e5e5;
    color: #5a6970;
    padding: 0 0 60px 0;
    margin-top: 40px;

    &.dark_section {
      background-color: #5a6970;
      color: var(--color-text-light);

      .content-title {
        color: inherit !important;
      }
    }

    .device-section {
      padding-bottom: 75px;
      > div {
        background-position: center;
        background-size: cover;
        height: 300px;
        ${breakpointsMedia(breakpoints.tablet)}{
          height: 600px;

          width: 50%;
          float: right;
        }
      }
      .iphone {
        ${breakpointsMedia(breakpoints.tablet)}{
          width: 50%;
          float: left;
        }
      }
      .iphone-pic {
        margin-top: 46px;
        display: inline-block;
        position: relative;

        .mobile_image.loader {
          position: absolute;
          width: 75.1%;
          height: 77%;
          top: 11.5%;
          left: 11.1%;
        }
      }
    }

    .iframe-section {
      &.tablet {
        .screen-window {
          width: 785px;
          .iframe {
            height: 450px;
          }
        }
      }
      &.mobile {
        .screen-window {
          width: 400px;
          .iframe {
            height: 600px;
          }
        }
      }
      .screen-window {
        margin: 60px auto 0;
        width: 1170px;
        max-width: 100%;
        padding: 31px 2px 0;
        background: #d9dfe2;
        position: relative;
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
        
        transition-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
        transition: all 0.5s;
        
        .iframe {
          height: 643px;
          transition-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
          transition: all 0.5s;
          width: 100%;
          position: relative;
          
          .tablet & {
            height: 450px;
          }
          .mobile & {
            height: 600px;
          }
          iframe {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            border: none;
            pointer-events: none;
          }
        }
        .circles {
          position: absolute;
          top: 0px;
          left: 28px;
          .circle {
            height: 9px;
            width: 9px;
            border-radius: 50%;
            background: #909496;
            display: inline-block;
            margin-right: 3px;
          }
        }
      }
    }
  }

  .iframe-btns {
    width: 975px;
    max-width: 100%;
    margin: 0 auto;
    cursor: pointer;
    .iframe-btn {
      display: block;
      color: #5a6970;
      text-transform: uppercase;
      opacity: 0.5;
      padding: 30px 0;
      width: 33.33333333333333%;
      float: left;
    
      transition-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
     transition: all 0.5s;
      i {
        margin-right: 10px;
      }
      &.active,
      &:hover,
      &:focus {
        opacity: 1;
        text-decoration: none;
      }
    }
`

export const SeeItInAction = ({ siteURL, leftImage, rightImage }) => {
  const [size, setSize] = useState("desktop")
  const { isMobile } = useMobile()

  const setMobile = () => setSize("mobile")
  const setTablet = () => setSize("tablet")
  const setDesktop = () => setSize("desktop")

  return (
    <SeeItInActionStyled
      id="see_it_in_action"
      className="section-container no-top "
    >
      <div className="device-section clearfix ">
        {leftImage && (
          <CustomBGImage className="tablet" {...leftImage?.localFile} />
        )}

        {rightImage && (
          <CustomBGImage className="iphone" {...rightImage?.localFile} />
        )}
      </div>

      {siteURL && !isMobile && (
        <div
          className={classnames("iframe-section hidden-xs hidden-sm", size)}
          id="responsive-screen"
        >
          <div className="container" style={{ textAlign: "center" }}>
            <Heading node="h2">See It In Action</Heading>

            <p>
              Use the dynamic responsive window to see how the site caters for
              different screen sizes and devices.
            </p>

            <div className="screen-window">
              <div className="circles">
                <span className="circle" />
                <span className="circle" />
                <span className="circle" />
              </div>

              <div className="iframe">
                <iframe
                  title="website responsive preview"
                  scrolling="no"
                  src={siteURL}
                />
              </div>
            </div>

            <div className="iframe-btns clearfix">
              <div
                role="button"
                className={classnames("iframe-btn mobile", {
                  active: size === "mobile",
                })}
                onClick={setMobile}
                onKeyDown={setMobile}
                tabIndex={0}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="mobile"
                  className="svg-inline--fa fa-mobile fa-w-10"
                  role="img"
                  viewBox="0 0 320 512"
                >
                  <path
                    fill="currentColor"
                    d="M272 0H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h224c26.5 0 48-21.5 48-48V48c0-26.5-21.5-48-48-48zM160 480c-17.7 0-32-14.3-32-32s14.3-32 32-32 32 14.3 32 32-14.3 32-32 32z"
                  />
                </svg>{" "}
                Mobile
              </div>

              <div
                role="button"
                className={classnames("iframe-btn tablet", {
                  active: size === "tablet",
                })}
                onClick={setTablet}
                onKeyDown={setTablet}
                tabIndex={0}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="tablet"
                  className="svg-inline--fa fa-tablet fa-w-14"
                  role="img"
                  viewBox="0 0 448 512"
                >
                  <path
                    fill="currentColor"
                    d="M400 0H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V48c0-26.5-21.5-48-48-48zM224 480c-17.7 0-32-14.3-32-32s14.3-32 32-32 32 14.3 32 32-14.3 32-32 32z"
                  />
                </svg>{" "}
                Tablet
              </div>

              <div
                role="button"
                className={classnames("iframe-btn desktop", {
                  active: size === "desktop",
                })}
                onClick={setDesktop}
                onKeyDown={setDesktop}
                tabIndex={0}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="desktop"
                  className="svg-inline--fa fa-desktop fa-w-18"
                  role="img"
                  viewBox="0 0 576 512"
                >
                  <path
                    fill="currentColor"
                    d="M528 0H48C21.5 0 0 21.5 0 48v320c0 26.5 21.5 48 48 48h192l-16 48h-72c-13.3 0-24 10.7-24 24s10.7 24 24 24h272c13.3 0 24-10.7 24-24s-10.7-24-24-24h-72l-16-48h192c26.5 0 48-21.5 48-48V48c0-26.5-21.5-48-48-48zm-16 352H64V64h448v288z"
                  />
                </svg>{" "}
                Desktop
              </div>
            </div>
          </div>
        </div>
      )}
    </SeeItInActionStyled>
  )
}

SeeItInAction.propTypes = {
  siteURL: PropTypes.string,
  leftImage: PropTypes.object,
  rightImage: PropTypes.object,
}
