import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Link } from "gatsby"

const CaseStudyArrowsStyled = styled.div`
  padding-top: 40px;
  padding-bottom: 40px;

  @media screen and (max-width: 767px) {
    display: none;
  }

  a {
    text-decoration: none;
  }
`

export const CaseStudyArrows = ({ nextCaseStudy, previousCaseStudy }) => {
  return (
    <CaseStudyArrowsStyled className="container next-prev-link-wrapper">
      <div className="row">
        <div className="container next-prev-links">
          <div className="row">
            <div className="col-xs-6 previous-post">
              {previousCaseStudy && (
                <Link
                  rel="prev"
                  to={previousCaseStudy.uri}
                  title={previousCaseStudy.title}
                  className="link pink"
                >
                  <strong className="heavy-font-weight">
                    Previous Project:
                  </strong>{" "}
                  <span
                    dangerouslySetInnerHTML={{
                      __html: previousCaseStudy.title,
                    }}
                  />
                </Link>
              )}
            </div>

            <div className="col-xs-6 next-post" style={{ textAlign: "right" }}>
              {nextCaseStudy && (
                <Link
                  rel="next"
                  to={nextCaseStudy.uri}
                  title={nextCaseStudy.title}
                  className="link pink"
                >
                  <strong className="heavy-font-weight">Next Project: </strong>{" "}
                  <span
                    dangerouslySetInnerHTML={{ __html: nextCaseStudy.title }}
                  />
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    </CaseStudyArrowsStyled>
  )
}

CaseStudyArrows.propTypes = {
  nextCaseStudy: PropTypes.shape({
    uri: PropTypes.string,
    title: PropTypes.string,
  }),
  previousCaseStudy: PropTypes.shape({
    uri: PropTypes.string,
    title: PropTypes.string,
  }),
}
